import React from 'react';

//countup
import CountUp from 'react-countup';

//intersection observer
import { useInView } from 'react-intersection-observer';

//motion
import {motion} from 'framer-motion';

//variant
import {fadeIn} from '../variants';


const About = () => {
  const [ref,inView]=useInView({
    threshold:0.5,
  });
  return(
    <section className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 md:flex-row md:items-center md:gap-x-20 md:gap-y-0 h-screen'>
          {/*img*/}
          <motion.div variants={fadeIn('right',0.3)} initial='hidden' whileInView={'show'} viewport={{once:false, amount:0.3}} className='flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top'></motion.div>

          {/*text*/}
          <motion.div variants={fadeIn('left',0.3)} initial='hidden' whileInView={'show'} viewport={{once:false, amount:0.3}} className='flex-1'>
            <h2 className='h2 text-accent md:mb-16'>About me</h2>
              <p className='mb-6 text-[12px] md:text-[15px]'>
              I am <i>Claire Lozada</i>,  a full-stack developer with over a year of professional experience in creating innovative web solutions. I am passionate about web development and enjoy designing and building web applications. My expertise lies in developing robust backend systems using Laravel's elegant MVC architecture, combined with seamless frontend experiences powered by Vue.js's reactive components, with state management supported by a PostgreSQL database.
              <br />
              <br />
              I often do both front-end and back-end web development. I believe a good website must visually pleasing to the eyes of the users and should be logically well-structured on the back end too. I enjoy turning beautiful designs into reality and making websites fully functional.
              </p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
