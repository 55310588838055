import React from 'react';

//icon
import { FaBootstrap, FaCss3, FaHtml5, FaVuejs, FaReact, FaAngular, FaFontAwesomeFlag, FaPhp, FaLaravel, FaGithub } from 'react-icons/fa';
import { SiTailwindcss, SiJavascript, SiJquery, SiMysql, SiPython, SiFirebase, SiKotlin, SiC } from "react-icons/si";
import { DiMysql, DiDatabase } from "react-icons/di";
import { BsGit } from 'react-icons/bs';

//motion
import { motion } from 'framer-motion';

//variants
import { fadeIn } from '../variants';

//education data
const education = [
  {
    date: '2019 - 2023',
    name: 'University of Baguio',
    address: 'Gen. Luna Rd., Baguio City',
    course: 'Bachelor of Science in Computer Science'
  },
  {
    date: '2017 - 2019',
    name: 'Tublay School of Home Industries-Main',
    address: 'Caponga, Tublay, Benguet',
    course: 'Science, Technology, Engineering, and Mathematics'
  },
  {
    date: '2013 - 2017',
    name: 'Tublay School of Home Industries-Main',
    address: 'Caponga, Tublay, Benguet',
    course: 'Computer Programming'
  }
];

const Services = () => {
  return (
    <section className='section' id='education'>
      <div className='container mx-auto'>
      <div className='flex flex-col gap-y-10 md:flex-row  md:gap-x-20 md:gap-y-0 h-screen'>
          {/*education */}
          <motion.div variants={fadeIn('right', 0.5)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.3 }} className='flex-1'>
            <h2 className='h2 text-accent'>Education</h2>
            <div >
              {education.map((education, index) => {
                //destructure education
                const { date, name, address, course } = education;
                return (
                  <div className='border-b border-white/20 h-[130px] mb-[20px] flex' key={index}>
                    <div className='max-w-[480px]'>
                      <h6 className='mb-6'>{name}</h6>
                      <p className='text-[12px] md:text-[15px] font-tertiary leading-tight font-light'>{address}</p>
                      <p className='text-[12px] md:text-[15px] font-tertiary leading-tight font-light'>{course}</p>
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                      <h4 className='text-[16px] tracking-wider font-primary font-semibold '>{date}</h4>
                    </div>
                  </div>
                )
              })}
            </div>
          </motion.div>

          {/*text */}
          <motion.div variants={fadeIn('left', 0.5)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.3 }} className='sm:max-w-sm md:max-w-sm'>
            <h2 className='h2 text-accent'>Skills</h2>
            <div className='mx-auto px-5 flex flex-wrap justify-between items-center '>
              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl '>
                {/* overlay */}
                <div className='absolute -top-full left-1 group-hover:top-2 transition-all duration-500 z-50 text-[12px]'>HTML</div>
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <FaHtml5 size={40} />
                {/* pretitle */}

              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <FaCss3 size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-2 group-hover:top-2 transition-all duration-500 z-50 text-[12px]'>CSS</div>
              </div>


              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <FaBootstrap size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-0.5 group-hover:top-2 transition-all duration-500 z-50 text-[8px]'>Bootstrap</div>
              </div>


              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <SiTailwindcss size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-0.5 group-hover:top-2 transition-all duration-500 z-50 text-[9px]'>Tailwind</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <SiJavascript size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-0.5 group-hover:top-2 transition-all duration-500 z-50 text-[7px]'>JavaScript</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <FaVuejs size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-1 group-hover:top-2 transition-all duration-500 z-50 text-[12px]'>VueJs</div>
              </div>


              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <FaReact size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-1 group-hover:top-2 transition-all duration-500 z-50 text-[12px]'>React</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <FaAngular size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-0.5 group-hover:top-2 transition-all duration-500 z-50 text-[9px]'>Angular</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <SiJquery size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-0.5 group-hover:top-2 transition-all duration-500 z-50 text-[10px]'>JQuery</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <FaFontAwesomeFlag size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-0 group-hover:top-2 transition-all duration-500 z-50 text-[6px]'>FontAwesome</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <FaPhp size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-2 group-hover:top-2 transition-all duration-500 z-50 text-[12px]'>PHP</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <FaLaravel size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-0.5 group-hover:top-2 transition-all duration-500 z-50 text-[10px]'>Laravel</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <DiDatabase size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-2 group-hover:top-2 transition-all duration-500 z-50 text-[12px]'>SQL</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <DiMysql size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-1 group-hover:top-2 transition-all duration-500 z-50 text-[10px]'>MySQL</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <SiFirebase size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-0.5 group-hover:top-2 transition-all duration-500 z-50 text-[9px]'>Firebase</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <BsGit size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-2.5 group-hover:top-2 transition-all duration-500 z-50 text-[12px]'>Git</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <FaGithub size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-0.5 group-hover:top-2 transition-all duration-500 z-50 text-[11px]'>Github</div>
              </div>


              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <SiPython size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-0.5 group-hover:top-2 transition-all duration-500 z-50 text-[10px]'>Python</div>
              </div>


              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <SiKotlin size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-1 group-hover:top-2 transition-all duration-500 z-50 text-[11px]'>Kotlin</div>
              </div>

              {/* icon */}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl m-1'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* image */}
                <SiC size={40} />
                {/* pretitle */}
                <div className='absolute -top-full left-3 group-hover:top-2 transition-all duration-500 z-50 text-[12px]'>C</div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
