import React from 'react';

//images
import Image from '../assets/avatar.png'

//icons
import { FaGithub, FaLinkedin, FaTelegram } from 'react-icons/fa'

//type animation
import { TypeAnimation } from 'react-type-animation';

//motion
import { motion } from 'framer-motion';

//variants
import { fadeIn } from '../variants';

const Banner = () => {
  return (
    <section className='min-h-[85vh] md:min-h-[78vh] flex items-center' id='home'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 md:flex-row md:items-center md:gap-x-12'>
          {/* text */}
          <div className='flex-1 text-center font-secondary md:text-left '>
            <motion.h1 variants={fadeIn('up', 0.3)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className='text-[25px] leading-[0.8] md:text-[45px]'>
              CLAIRE <span>LOZADA</span>
            </motion.h1>
            <motion.div variants={fadeIn('up', 0.4)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className='my-6 text-[20px] md:text-[25px] font-secondary font-semibold uppercase leading-[1]'>

              <TypeAnimation sequence={[
                'Junior Developer',
                2000,
                'Junior Web Developer',
                2000,
                'Junior Full-Stack Developer',
                2000,
              ]}
                speed={50}
                className='text-accent' wrapper='span' repeat={Infinity} />
            </motion.div>
            <motion.p variants={fadeIn('up', 0.5)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className=' text-[12px] md:text-[15px] mx-w-lg md:my-16 mx-auto md:mx-0 italic'>"First, solve the problem. Then, write the code."</motion.p>
            <motion.div variants={fadeIn('up', 0.6)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className='flex max-w-max gap-x-6 items-center mb-12 mx-auto md:mx-0'>
              <a href='#contact' className='button btn btn-lg pt-3'>Contact me</a>
              <a href='#work' className='text-white btn-1'>My Portfolio</a>
            </motion.div>
            {/* socials */}
            <motion.div variants={fadeIn('up', 0.7)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className='flex text-[20px] gap-x-6 max-w-max mx-auto md:mx-8'>
              <a href='https://github.com/claireL00' target='_blank'>
                <FaGithub />
              </a>
              <a href='https://www.linkedin.com/in/claire-lozada-630697170/' target='_blank'>
                <FaLinkedin />
              </a>
              <a href='https://t.me/claireLozada' target='_blank'>
                <FaTelegram />
              </a>
            </motion.div>
          </div>


          {/* image */}
          <motion.div variants={fadeIn('down',0.5)} initial='hidden' whileInView={'show'} 
          className='hidden md:flex flex-1 max-w-[320px] md:max-w-[482px]'>
            <img src={Image} className='mix-blend-normal' />
          </motion.div>
        </div>
      </div>
    </section>
  );
}
export default Banner;
