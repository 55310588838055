import React from 'react';

//motion
import { motion } from 'framer-motion';

//variants
import { fadeIn } from '../variants'

//images
import Img1 from '../assets/chedhr0.png';
import Img2 from '../assets/ubtelecare0.png';
import Img3 from '../assets/saoms.png';
import Img4 from '../assets/hems0.png';

const Work = () => {
  return (
    <section className='section  mb-11 md:mb-[9rem]' id='work'>
      <div className='container mx-auto'>
        <div className='gap-x-10'>
          <div className='flex-1 flex flex-col gap-y-12'>
            {/* text */}
            <div>
              <h2 className='h2 leading-tight text-accent'>
                My Latest Projects
              </h2>
              <p className='text-[12px] md:text-[15px]'>
                These projects are accomplished during my internship and as a school requirements. Most are made of PHP language.
              </p>
            </div>

            {/* image */}
            <motion.div variants={fadeIn('right', 0.4)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.3 }} className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              {/* image */}
              <img className='group-hover:scale-125 transition-all duration-500' src={Img1} alt='CHED HR System' />
              {/* pretitle */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-[7rem] md:group-hover:bottom-24 transition-all duration-500 z-50 text-3xl'>
                <span className='text-gradient'>CHED HR System</span>
              </div>
              {/* title */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-5 transition-all duration-700 z-50'>
                <p className=' text-[12px] md:text-[15px] text-white leading-[1.5rem] '>
                  an employee profiling system allowing users to view their personal files and processes for filing leave applications.
                  Developed with PHP, VueJs, MySQL, HTML, CSS, Bootstrap, JavaScript, and JQuery.
                </p>
              </div>
            </motion.div>

            {/* image */}
            <motion.div variants={fadeIn('left', 0.4)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.3 }} className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              {/* image */}
              <img className='group-hover:scale-125 transition-all duration-500' src={Img2} alt='UB Telecare' />
              {/* pretitle */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-[10rem] md:group-hover:bottom-[8rem] transition-all duration-500 z-50 text-3xl'>
                <span className='text-gradient'>UB Telecare</span>
              </div>
              {/* title */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-5 transition-all duration-700 z-50'>
                <p className=' text-[12px] md:text-[15px] text-white leading-[1.5rem] '>
                  a record management and telemedicine system with video-conferencing features made for the University of Baguio Medical Clinic to hasten their services.
                  
                  Developed with  PHP Laravel, Vuejs, MySQL, HTML, CSS, Bootstrap, JavaScript, JQuery, CanvasJs,  Agora SDK, and Pusher.
                </p>
              </div>
            </motion.div>


            {/* image */}
            <motion.div variants={fadeIn('right', 0.4)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.3 }} className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              {/* image */}
              <img className='group-hover:scale-125 transition-all duration-500' src={Img3} alt='Succulent Alpha OMS' />
              {/* pretitle */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-[7rem] md:group-hover:bottom-24 transition-all duration-500 z-50 text-3xl'>
                <span className='text-gradient'>Succulent Alpha OMS</span>
              </div>
              {/* title */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-5 transition-all duration-700 z-50'>
                <p className=' text-[12px] md:text-[15px] text-white leading-[1.5rem] '>
                 an e-commerce, order management website that sells succulents to customers.

                  Developed with  PHP Laravel, MYSQL, HTML, CSS, Bootstrap,  JavaScript, JQuery, and CanvasJs.
                </p>
              </div>
            </motion.div>


            {/* image */}
            <motion.div variants={fadeIn('left', 0.5)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.3 }} className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              {/* image */}
              <img className='group-hover:scale-125 transition-all duration-500' src={Img4} alt='Hip Event Management System' />
              {/* pretitle */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-[8rem] md:group-hover:bottom-24 transition-all duration-500 z-50 text-3xl'>
                <span className='text-gradient'>Hip Event Management System</span>
              </div>
              {/* title */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-5 transition-all duration-700 z-50'>
                <p className=' text-[12px] md:text-[15px] text-white leading-[1.5rem] '>
                an event management system that aims to help event organizers with the booking and organizing of their events.

                  Developed with  PHP Laravel, MYSQL, HTML, CSS, Bootstrap, JavaScript, JQuery, AJAX, and ChartJs.

                </p>
              </div>
            </motion.div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
