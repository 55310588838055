import React from 'react';

//motion
import { motion } from 'framer-motion';

//variants
import {fadeIn} from '../variants';


const Contact = () => {
  return (
    <section className='md:-mt-6 md:section' id='contact'>
        <div className='container mx-auto '>
          <div className='flex flex-col md:flex-row'>
            {/* text */}
            <motion.div variants={fadeIn('right', 0.3)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.3 }} className='flex-1 flex justify-start items-center'>
              <div>
                <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide'>Get in touch</h4>
                <h2 className='text-[50px] md:text-[80px] leading-none mb-12'>
                  Let's work <br/> together!
                </h2>
              </div>
            </motion.div>

            {/* form */}
            <motion.form variants={fadeIn('left', 0.3)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.3 }} className='flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start' action="mailto:clairelozada07@gmail.com" method="post" enctype="text/plain">
              <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all text-[12px] md:text-[15px]' type="text" placeholder='Your name'/>
              <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all text-[12px] md:text-[15px]' type="email" placeholder='Your email'/>
              <textarea  className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12 text-[12px] md:text-[15px]' placeholder='Your message'></textarea>
              <button className='btn btn-lg'>Send message</button>
            </motion.form>

          </div>
        </div>
    </section>
  );
};

export default Contact;
